<template>
  <div class="inline-text-field">
    <div class="field" v-if="focused">
      <v-text-field
        ref="field"
        class="title-field"
        maxlength="25"
        :value="value"
        @input="update"
        @keyup.enter="save"
      >
      </v-text-field>
      <v-btn
        icon
        @click="save"
        :ripple="false"
      >
        <save-icon />
      </v-btn>
      <v-btn
        icon
        @click="cancel"
        :ripple="false"
      >
        <cancel-icon />
      </v-btn>
    </div>

    <div v-else style="cursor: pointer">
      <span @click="toggleEdit">
        {{ value }}
      </span>

      <v-btn icon @click="toggleEdit" :ripple="false" >
        <edit-icon />
      </v-btn>
    </div>
  </div>
</template>

<script>
import EditIcon from './icons/EditIcon.vue';
import SaveIcon from './icons/SaveIcon.vue';
import CancelIcon from './icons/CancelIcon.vue';

export default {
  components: {
    EditIcon,
    SaveIcon,
    CancelIcon
  },

  props: {
    value: { type: String, requried: true }
  },

  data() {
    return {
      focused: false,
      newValue: '',
    }
  },

  methods: {
    toggleEdit() {
      this.focused = !this.focused;

      if (this.focused) {
        this.newValue = this.value;
        this.$nextTick(() => this.$refs.field.focus());
      }
    },
    update(value) {
      this.newValue = value;
    },
    save() {
      this.$emit('save', this.newValue);
      this.focused = false;
    },
    cancel() {
      this.focused = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.inline-text-field {
  font-weight: 600;
  font-size: 22px;

  line-height: 100%;

  color: #252733;
}

.v-btn:before {
  background-color: transparent !important;
}

.title-field {
  max-width: 250px;
}

.field {
  flex-flow: row nowrap;
  display: flex;
  align-items: center;
}
</style>
